<template>
  <v-container id="signinup-form" class="fill-height">
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12" sm="8" md="8" class="">
        <v-card class="evelation-12 card">
          <v-window v-model="step">
            <!--SignIn-->
            <v-window-item :value="1">
              <v-row class="">
                <v-col cols="12" md="8" class="pt-6 pb-6">
                  <v-card-text>
                    <v-form class="signup-form-form" v-model="valid1">
                      <h2
                        class="text-center display-1 mb-10"
                        :class="`${bgColor}--text`"
                      >
                        Sign in SPMB
                      </h2>
                      <v-text-field
                        v-model="email"
                        label="Email"
                        prepend-icon="mdi-account"
                        type="text"
                        :rules="[rules.required, rules.email]"
                        :color="bgColor"
                      ></v-text-field>
                      <v-text-field
                        v-model="password"
                        id="password"
                        label="Password"
                        name="password"
                        prepend-icon="mdi-lock"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show1 ? 'text' : 'password'"
                        hint="At least 8 characters"
                        counter
                        @click:append="show1 = !show1"
                        :color="bgColor"
                      ></v-text-field>
                      <div class="text-center">
                        <a
                          href="#"
                          class="mt-3 overline no-text-decoration"
                          :class="`${bgColor}--text`"
                          @click="step = 3"
                        >
                          Forgot your password?
                        </a>
                      </div>
                      <div class="text-center mt-6">
                        <v-btn
                          large
                          :color="bgColor"
                          :disabled="!valid1"
                          @click="loginUser"
                          >Login</v-btn
                        >
                      </div>
                    </v-form>
                  </v-card-text>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  class="darken-2 vcenter"
                  :class="`${bgColor}`"
                >
                  <div>
                    <v-card-text :class="`${fgColor}--text`">
                      <h1 class="text-center headline mb-3">No User?</h1>
                      <h5 class="text-center overline mb-3">
                        Please Sign Up to continue
                      </h5>
                    </v-card-text>
                    <div class="text-center mb-2">
                      <v-btn dark outlined @click="step = 2">Sign Up</v-btn>
                    </div>
                    <div class="text-center mb-2">
                      <v-btn dark outlined to="/">Back To Home</v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-window-item>
            <!--SignUp-->
            <v-window-item :value="2">
              <v-row class="fill-height">
                <v-col
                  cols="12"
                  md="4"
                  class="darken-2 vcenter"
                  :class="`${bgColor}`"
                >
                  <div>
                    <v-card-text :class="`${fgColor}--text`">
                      <h1 class="text-center headline mb-3">Already a user?</h1>
                      <h5 class="text-center overline mb-3">Please Sign In</h5>
                    </v-card-text>
                    <div class="text-center mb-6">
                      <v-btn dark outlined @click="step = 1">Sign In</v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="8" class=" pt-6 pb-6">
                  <v-card-text>
                    <h1
                      class="text-center display-1 mb-10"
                      :class="`${bgColor}--text`"
                    >
                      Sign Up
                    </h1>
                    <v-form class="signup-form-form" v-model="valid2">
                      <v-text-field
                        v-model="first_name"
                        label="Full Name"
                        prepend-icon="mdi-account"
                        type="text"
                        :rules="[rules.required]"
                      />
                      <v-text-field
                        v-model="phone"
                        label="phone"
                        prepend-icon="mdi-phone"
                        type="text"
                        :rules="[rules.required]"
                      />
                      <v-text-field
                        v-model="email"
                        label="eMail"
                        type="email"
                        :rules="[rules.required, rules.email]"
                        prepend-icon="mdi-mail"
                      />
                      <v-text-field
                        v-model="password"
                        label="Password"
                        prepend-icon="mdi-lock"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show1 ? 'text' : 'password'"
                        hint="At least 8 characters"
                        counter
                        @click:append="show1 = !show1"
                      />
                      <div class="text-center mt-6">
                        <v-btn
                          large
                          :color="bgColor"
                          :disabled="!valid2"
                          class="mr-4 primary"
                          @click="register"
                          >Register</v-btn
                        >
                      </div>
                    </v-form>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-window-item>
            <!--PW Rest-->
            <v-window-item :value="3">
              <v-row class="fill-height">
                <v-col
                  cols="12"
                  md="4"
                  class="darken-2 vcenter"
                  :class="`${bgColor}`"
                >
                  <div>
                    <v-card-text :class="`${fgColor}--text`">
                      <h1 class="text-center headline mb-3">Already a user?</h1>
                      <h5 class="text-center overline mb-3">Please Sign In</h5>
                    </v-card-text>
                    <div class="text-center mb-6">
                      <v-btn dark outlined @click="step = 1">Sign In</v-btn>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="8" class="pt-6 pb-6">
                  <v-card-text>
                    <v-form class="signup-form-form" v-model="valid3">
                      <h1
                        class="text-center display-1 mb-10"
                        :class="`${bgColor}--text`"
                      >
                        Reset Password
                      </h1>
                      <v-text-field
                        v-model="reset"
                        label="eMail"
                        type="text"
                        :rules="[rules.required, rules.email]"
                        :color="bgColor"
                        class="v-input__icon--double"
                        prepend-icon="mdi-mail"
                      />
                      <div class="text-center mt-6">
                        <v-btn
                          large
                          :color="bgColor"
                          dark
                          @click="sendMail"
                          :disabled="!valid3"
                          >Reset Password</v-btn
                        >
                      </div>
                    </v-form>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SignIn",
  components: {
    //
  },
  props: {
    source: {
      type: String,
      default: ""
    },
    bgColor: {
      type: String,
      default: "indigo"
    },
    fgColor: {
      type: String,
      default: "white"
    }
  },

  data: () => ({
    step: 1,
    valid1: false,
    valid2: false,
    valid3: false,
    reset: "",
    email: "",
    password: "",
    first_name: "",
    phone: "",
    status: null,
    message: null,
    show1: false,
    rules: {
      required: value => !!value || "Required.",
      min: v => v.length >= 8 || "Min 8 characters",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      }
    }
  }),
  computed: {
    ...mapGetters("auth", ["userInfo"])
  },
  methods: {
    loginUser() {
      this.$store
        .dispatch("auth/loginUser", {
          email: this.email,
          password: this.password
        })
        .then(() => {
          const user = this.userInfo;
          if (user.role === "1") {
            this.$alert("Berhasil Login", "Success", "success");
            this.$router.push("/admin");
          } else {
            this.$alert("Berhasil Login", "Success", "success");
            this.$router.push("/user");
          }
        })
        .catch(err => {
          this.status = err;
          this.$alert(err, "Error", "error");
        });
    },
    register() {
      this.$confirm("Are you sure?", "Confirm", "question")
        .then(() => {
          this.$store
            .dispatch("auth/register", {
              first_name: this.first_name,
              last_name: this.last_name,
              email: this.email,
              password: this.password,
              phone: this.phone
            })
            .then(() => {
              this.$alert(
                "Selamat" +
                  this.first_name +
                  "Anda Terdaftar Pada Sistem Kami, Silahkan Aktivasi Akun",
                "Success",
                "success"
              );
              this.$router.push("/login");
            })
            .catch(err => {
              this.$alert(err.response.data, "Error", "error");
            });
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    },
    sendMail() {
      this.$confirm("Are you sure reset the Password", "Confirm", "question")
        .then(() => {
          this.$store
            .dispatch("auth/sendMail", {
              email: this.reset
            })
            .then(succ => {
              this.$alert(succ.message, "Success", "success");
            })
            .catch(err => {
              this.$alert(err.response.data, "Error", "error");
            });
        })
        .catch(() => {
          console.log("OK not selected.");
        });
    }
  }
};
</script>

<style scoped lang="scss">
.v-input__icon--double .v-input__icon {
  margin-left: -4.25rem !important;
}
a.no-text-decoration {
  text-decoration: none;
}
// #signinup-form {
//   max-width: 75rem;
// }
.signup-form-form {
  max-width: 23rem;
  margin: 0 auto;
}
.card {
  overflow: hidden;
}
.vcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
